.container {
  padding-top: 210px;
}


@media screen and (max-width: 600px){
  
  .carousel {
    border-radius: 30px;
  }

}
