.carousel {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
}

.carousel-img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
}

/* responsive */

@media screen and (max-width: 600px) {

    .carousel {
        border-radius: 0px;
    }

    .carousel-img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16/12;
    }
    
}