header {
    background: #fffffff1;
    backdrop-filter: blur(5px);
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

header .sem-logo {
    width: 150px;
    margin: 10px 0px 10px 100px;
    transition: 1s;
}

header .navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

header .navigation li {
    display: inline-flex;
}


header .navigation li a {
    display: block;
    color: #000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    transition: 1s;
}

header .navigation li a:hover {
    color: #979797;
}

header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

header.sticky .sem-logo {
    width: 70px;
    margin: 10px 0px 10px 100px;
    transition: 1s;
}

header.sticky .navigation li a {
    font-size: 15px;
    transition: 1s;
}

.navigation .icon {
    position: relative;
    top: -2px;
}

.toggle-menu {
    display: none !important;
}

.d-none {
    display: none;
    transition: 1s;
}
/* responsive */

@media screen and (max-width: 1000px) {
    header {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    header .navigation  {
        width: 100%;
        height: 100vh;
        flex-direction: column;
        display: none;
    }

    header .navigation.active {
        display: flex;
        justify-content: flex-start;
    }

    header .navigation li {
        display: block;
        width: 100%;
        border-top: 0.1px solid rgba(255, 255, 255, 0.048);
    }

    header .navigation li a {
        margin: 20px 0;
        font-size: 17px;
    }

    header .sem-logo {
        width: 100px;
        margin: 10px 0 10px 30px;
        transition: 1s;
    }

    header.sticky .sem-logo {
        width: 100px;
        margin: 10px 0 10px 30px;
        transition: 1s;
    }

    .toggle-menu {
        display: block !important;
        position: absolute !important;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        width: 30px;
    }
}

@media screen and (max-width: 600px) {
    header .sem-logo {
        width: 80px;
    }

    header.sticky .sem-logo {
        width: 80px;
    }

    header .navigation.active {
        display: flex;
        justify-content: flex-start;
    }
}