.trofeu {
    width: 40%;
}

.confete {
    position: fixed;
    width: 100%;
    left: 0;
}

.premiados-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}