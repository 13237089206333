.insta-icon {
    background: linear-gradient(to right, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040);
}

.insta-icon:hover {
    background: linear-gradient(to right, #F56040, #FD1D1D, #E1306C, #C13584, #833AB4, #5851DB, #405DE6, #833AB4);
}

.btn-promo {
    font-size: 24px;
    font-weight: bold;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white !important;
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        background-color: rgb(255, 0, 0);
        transform: scale(1);
    }
    20% {
        background-color: rgb(255, 165, 0);
        transform: scale(1.05);
    }
    40% {
        background-color: rgb(255, 115, 0);
        transform: scale(1);
    }
    60% {
        background-color: rgb(0, 128, 0);
        transform: scale(1.05);
    }
    80% {
        background-color: rgb(0, 0, 255);
        transform: scale(1);
    }
    100% {
        background-color: rgb(75, 0, 130);
        transform: scale(1.05);
    }
}