.container {
    padding-top: 150px;
}

.contact-icons {
    font-size: 4rem !important;
}

.wppic {
    color: #25d366;
}

.emailic {
    color: #d93025;
}

.instaic {
    width: 4rem;
    height: 4rem;
    background: linear-gradient(45deg, #405de6, #833ab4, #c13584, #e1306c, #fd1d1d) !important;
    color: white;
    border-radius: 20px;
    text-align: center;
}

.instaic>*{
    font-size: 3rem !important;
    margin-top: 0.5rem;
}

.instaic:hover {
    background: linear-gradient(45deg, #fd1d1d, #e1306c, #c13584, #833ab4, #405de6) !important;
}

.faceic {
    color: #3b5998;
}

.text-contact {
    text-decoration: none !important;
    color: black;
}

.rf-light {
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: 1rem;;
}

.redes a {
    margin-right: 10px;
    padding: 10px;
    background: #048992;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.redes .wpp-icon {
    background-color: #01A884;
}

.redes .wpp-icon:hover {
    background-color: #128C7E;
}

.redes .insta-icon {
    background: linear-gradient(to right, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040);
}

.redes .insta-icon:hover {
    background: linear-gradient(to right, #F56040, #FD1D1D, #E1306C, #C13584, #833AB4, #5851DB, #405DE6, #833AB4);
}

.redes .face-icon {
    background-color: #3b5998;
}

.redes .face-icon:hover {
    background-color: #4267B2;
}

.redes .x-icon {
    background-color: #000;
}

.redes .x-icon .ic-prf {
    margin-right: 0;
}

.redes .x-icon:hover {
    background-color: #333;
}

.redes .ic-prf {
    margin-right: 10px;
}

.redes .ic-prf * {
    width: 20px;
}