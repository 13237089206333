#container {
    width: 80%;
    margin: 0 auto;
}

section.slide {
    margin-top: 210px;
}

.title {
    margin: 40px auto;
}

.organizer {
    display: flex;
    flex-direction: row;
}

.img-perfil {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
}

.perfil .label-name {
    font-size: 10px;
    margin-bottom: 8px;
}

.perfil .redes {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;
}

.perfil .redes a {
    margin-right: 10px;
    padding: 10px;
    background: #048992;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
}

.perfil .redes .wpp-icon {
    background-color: #01A884;
}

.perfil .redes .wpp-icon:hover {
    background-color: #128C7E;
}

.perfil .redes .insta-icon {
    background: linear-gradient(to right, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040);
}

.perfil .redes .insta-icon:hover {
    background: linear-gradient(to right, #F56040, #FD1D1D, #E1306C, #C13584, #833AB4, #5851DB, #405DE6, #833AB4);
}

.perfil .redes .face-icon {
    background-color: #3b5998;
}

.perfil .redes .face-icon:hover {
    background-color: #4267B2;
}

.perfil .redes .x-icon {
    background-color: #000;
}

.perfil .redes .x-icon .ic-prf {
    margin-right: 0;
}

.perfil .redes .x-icon:hover {
    background-color: #333;
}

.perfil .redes .ic-prf {
    margin-right: 10px;
}

.perfil .redes .ic-prf * {
    width: 20px;
}

.cardw3 {
    width: 100%;
    margin: 20px auto;
    padding: 25px;
    border-radius: 20px;
    border: 0.1px solid #dfdfdf;
    background-color: #fff;
}

.description, .checkout { 
    margin: 50px 0;
}

.checkout {
    margin-bottom: 30px;
}

.title-section {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #8a8a8a;
}

.desc-text {
    white-space: pre-line;
    text-align: justify;
    padding: 0 2rem;
}

.sorteio-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sorteio-info>div {
    text-align: center;
    width: 40%;
}

.icon-pix {
    width: 20px;
    position: relative;
    top: -3px;
    color: #01A884 !important;
}

.ranking {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    flex-wrap: wrap;
}

.ranking>div {
    margin: 10px 40px;
}

.ranking .posicao {
    font-size: 40px;
    margin-bottom: .7rem;
}

.ranking .nome {
    margin-bottom: .7rem;
}

.ranking .qtd span {
    font-weight: bold;
}

.premios {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    flex-wrap: wrap;
}

.premios .premio {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.premios>div {
    margin: 10px 20px;
}

.premios .posicao {
    font-size: 40px;
    margin-bottom: .7rem;
}

/* .restante>span {
    color: #FD1D1D;
} */

.text-low {
    font-size: 0.9rem;
}

/* responsive */

@media screen and (max-width: 600px) {

    #container {
        width: 90%;
    }

    section.slide {
        margin-top: 10rem;
        margin-bottom: 2.5rem;
    }

    .card {
        border: none;
    }

    .cardw3 {
        margin-bottom: 4rem;
    }

    .desc-text {
        padding: 0 0.1rem;
    }

    .perfil {
        width: 80%;
    }

    .perfil .redes { 
        flex-wrap: nowrap;
        position: relative;
        top: 19px;
        left: -83px;
    }

    .perfil .redes a {
        font-size: 10px;
    }
}