.rh-contagem div {
    width: 200px;
}

@media screen and (max-width: 768px) {
    .rh-contagem div {
        width: 140px;
        padding: 20px 10px !important;
    }
}

@media screen and (max-width: 480px) {
    .rh-contagem div {
        width: 110px;
        padding: 10px 5px !important;
    }
}