.pay>*{
    margin-bottom: 2rem;
}

.payic {
    color: #1cc09d;
    margin-right: 10px;
    position: relative;
    top: -2.5px;
}

.qrcode img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    display: block;
    max-width: 260px;
    max-height: 260px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.infos h5 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.pag-infos span {
    font-weight: 600;
}

.infos {
    padding-top: 20px !important;
    margin-bottom: 2rem;
}

.aguardando {
    position: relative;
    top:12px;
    margin-left: 20px;
}

.copiaecola {
    cursor: pointer;
    background-color: #1cc09d !important;
    color: white !important;
}

.pag-instrucoes {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pag-instrucoes .num {
    font-weight: 600;
    min-height: 50px;
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
    padding-top: 12px;
    border: #1cc09d 1px solid;
    color: #1cc09d;
    border-radius: 50%;
    margin-right: 1rem;
}