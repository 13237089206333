.ticket {
    width: 50%;
    margin: 0 auto;
}

.title-section {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #8a8a8a;
}

.btns-bilhetes {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
    text-align: center;
}

.btns-bilhetes>div {
    border: 0.1px solid #dfdfdf;
    padding: 2rem 2rem;
    border-radius: 10px;
    margin: 20px 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    min-width: 100px;
}

.btns-bilhetes>.mais-popular {
    padding: 1.5rem 2rem !important;
    position: relative;
    top: 17px;
    height: 80%;
}

.btns-bilhetes>div:hover {
    background-color: #f2f2f2;
}

.mais-popular {
    position: relative;
    border: 0.1px solid #4bbe47 !important;
}

.mais-popular::after {
    width: 102%;
    text-align: center;
    content: "Mais Popular";
    color: #fff;
    background-color: #4bbe47;
    font-size: 13px;
    font-weight: 700;
    margin: 0 auto;
    padding: 5px;
    border-radius: 10px 10px 0px 0;
    position: absolute;
    top: -20px;
    left: -1px;
}

.input-bilhetes {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.input-bilhetes input {
    width: 80%;
    padding: 10px;
    border: 0.1px solid #dfdfdf;
    border-radius: 10px;
    margin: 10px 20px;
    text-align: center;
}

.btn-menos, .btn-mais {
    border: 0.1px solid #dfdfdf;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
}

.resultado {
    display: flex;
    justify-content: space-between;
    color: #696969;
    margin: 15px auto;
}

.btn-comprar {
    border: 0.1px solid #dfdfdf;
    padding: 10px 60px;
    border-radius: 10px;
    margin: 15px auto;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 700;
    background-color: #4bbe47;
    color: #fff;
    text-align: center;
}

@media screen and (max-width: 895px) {

    .ticket {
        width: 100%;
    }
    
}

@media screen and (max-width: 1000px) {

    .btns-bilhetes>div {
        min-width: 40%;
        margin: 10px 10px;
    }
    
}

@media screen and (max-width: 350px) {

    .btns-bilhetes>div {
        min-width: 40%;
    }
    
}