body {
    margin: 0 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}